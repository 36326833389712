<template>
    <div class="edit-nickname-box" id="edit-nickname-box">
        <title-nav :title="'修改昵称'"/>
        <div class="text">
            <img src="../../assets/images/warning.png" alt="" width="25" height="25">
            <span>支持3-30个字符,支持中英文、数字</span>
        </div>
        <input v-model="inputVal" placeholder="请输入" ref="input" type="text" maxlength="30" @keyup.enter="verifyIdentity"/>
        <div class="login-button" @click="verifyIdentity">
            <van-loading color="white" size="24px" v-show="isLoading"/>
            确认修改
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
import { updateNickname } from '@/api/api'

export default {
    name: 'EditNickname',
    components: {
        TitleNav
    },
    created(){

    },
    mounted(){
        this.$refs.input.focus()
        if(this.$store.state.userAgent !== 'Mobile'){
            document.getElementById("edit-nickname-box").style.cssText= 'margin-top: 58px';
        }
    },
    data:()=>{
        return {
            inputVal: JSON.parse(localStorage.getItem('userInfo'))[0].nickname,
            uid: JSON.parse(localStorage.getItem('userInfo'))[0].uid,
            isLoading: false
        }
    },
    methods:{
        verifyIdentity(){
            if(this.inputVal.length >= 3){
                this.isLoading = true
                updateNickname({ uid: this.uid, nickname: this.inputVal }).then(()=>{
                    console.log('昵称更新成功')
                    this.$router.push('/edit-personal')
                    this.isLoading = false
                })
            } else {
                this.$notify({
                    message: '支持3-30个字符,支持中英文、数字',
                    duration: 1000,
                    background: '#ea6f7b'
                })
            }
        }
    },
}
</script>

<style scoped lang="scss">
.edit-nickname-box {
    background: #fff;
    min-height: 100vh;
    box-sizing: border-box;
    max-width: 625px;
    margin: 0 auto;
    .text {
        text-align: left;
        display: flex;
        align-items: center;
        margin: 10px;
        span {
            margin-left: 10px;
        }
    }
    input {
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        background: #fefefe;
        padding: 0 10px;
        border: none;
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #ddd;
    }
    .login-button {
        margin: 30px auto;
        border: 1px solid #ec745b;
        background: #ec745b;
        color: #FFF;
        width: 80%;
        height: 45px;
        line-height: 45px;
        border-radius: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.van-loading {
    position: relative !important;
    left: 0;
    top: 0;
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    font-size: 0;
    vertical-align: middle;
    -ms-transform: none;
}
</style>